<template>
    <v-container fluid fill-height class="backcolor" style="height:70px">
        <v-layout child-flex row fill-height align-center>
            <!-- Horizontal: text-xs-center -->
            <v-flex xs6 align-self-start>
                <v-container fluid fill-height mt-0 pt-0 ml-3 class="text-left">
                    <v-img alt="TurismoBC Logo" class="shrink hidden-sm-and-down"
                           contain
                           max-width="100"
                           max-height="40"
                           height="50"
                           width="auto"
                           :src="company.logo_url != null ? company.logo_url : require('@/assets/images/no-image.jpg')"
                           :lazy-src="require('@/assets/images/no-image.jpg')"/>
                </v-container>
            </v-flex>
            <v-flex xs6 align-self-start>
                <v-container grid-list-lg fill-height mt-0 md-5 class="text-right">
                    <v-layout column wrap fill-height>
                        <v-flex>
                        <span class="text-body-2 white--text" style="margin-left: 5px">
                            &copy;2022. Todos los Derechos Reservados.
                        </span>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import companyService from "@/providers/CompanyService";
    
    export default {
        name: "FooterProfileComponent",
        data: () => ({
            company: {
                id: 0,
                name: "",
                address: "",
                email: "",
                web_page: "",
                phone1: "",
                phone2: "",
                facebook_profile: "",
                instagram_profile: "",
                youtube_video: "",
                logo_url: ""
            },
        }),
        methods: {
            getCompany() {
                companyService.getViewData().then(record => {
                    // console.log("Record for Company: ", record.value);
                    if (record.value !== null) {
                        this.company = record.value;
                        // console.log("company: ", this.company);
                    }
                });
            }
        },
        beforeMount() {
            this.getCompany();
        }
    }
</script>

<style scoped>
    .backcolor {
        background-color: rgba(21, 27, 41, 1);
    }
    .v-btn {
        text-transform: none !important;
    }
</style>